import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

export default {
  getDBState(ctx) {
    ctx.dbStateList = [];
    const config = {
      params: {
        // action: 'R'
      }
    };
    ctx.$store.commit('loadingDBStateListBegin');
    Vue.axios.get(`${API_URL || ''}/api/db_resample/state`, config).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist)
          ctx.dbStateList = JSON.parse(JSON.stringify(response.data.data.itemslist));
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingDBStateListEnd');
      },
      (err) => {
        ctx.$store.commit('loadingDBStateListEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  }
};
